<template>
	<div class="dash-card" :class="computedClass">
		<div class="dash-card__header" :class="headerClass">
			<div class="dash-card__title-wrapper" :class="centeredTitle && 'justify-center'">
				<div v-if="!mainTitle" class="mr-4 d-flex align-center">
					<slot name="icon">
						<v-icon left dark>{{ icon }}</v-icon>
					</slot>
				</div>
				<slot name="title">
					<div class="dash-card__title">{{ title }}</div>
				</slot>
			</div>

			<div class="dash-card__title-info">
				<slot name="updated">
					<div v-if="updatedOn" class="dash-card__info-updated">
						<span class="dash-card__info-updated-label">Updated&nbsp;on:</span>
						<span><strong>{{ updatedOn }}</strong></span>
					</div>
				</slot>
				<v-btn
					class="dash-card__info-button"
					icon
					:disabled="infoData.loading || infoData.error"
					@click="doShowDialog = true"
				>
					<v-icon>icon-info-circle-q</v-icon>
				</v-btn>
			</div>
		</div>

		<div class="dash-card__divider-container">
			<v-progress-linear v-if="loading" :height="2" indeterminate />
			<v-divider v-if="!mainTitle && !loading" class="dash-card__header-divider" />
		</div>

		<div class="dash-card__content">
			<div v-if="hasError" class="dash-card__content-no-data">
				<slot name="error">
					<div class="dash-card__content-error">
						<span>
							An error occurred while loading data. You can reload the whole page or just this card
						</span>
						<v-btn
							class="error-btn mt-2" elevation="0"
							:small="isMobileView"
							color="q_blue_1"
							@click="$emit('reload')"
						>
							<v-icon>fas fa-sync-alt</v-icon>
							<v-divider class="mx-2" color="white" vertical />
							Reload Card
						</v-btn>
					</div>
				</slot>
			</div>
			<div v-else-if="noData" class="dash-card__content-no-data">
				<slot name="noData">No data available</slot>
			</div>
			<div class="dash-card__content-data">
				<slot />
			</div>
		</div>

		<InfoDialog
			v-model="doShowDialog"
			:title="infoBlockTitle"
			:content="infoBlockContent"
			:loading="infoData.loading"
		>
			<slot name="info" />
		</InfoDialog>
	</div>
</template>

<script>
import InfoDialog from '../Components/InfoDialog.vue'

export default {
    components: {
		InfoDialog,
	},
	props: {
		loading: {
			type: Boolean,
			default: false,
		},
		silentLoading: {
			type: Boolean,
			default: false,
		},
		hasError: {
			type: Boolean,
			default: false,
		},
		noData: {
			type: Boolean,
			default: false,
		},
		dark: {
			type: Boolean,
			default: false,
		},
		mainTitle: {
			type: Boolean,
			default: false,
		},
		centeredTitle: {
			type: Boolean,
			default: false,
		},
		title: {
			type: String,
			default: 'Title',
		},
		icon: {
			type: String,
			default: 'fas fa-file',
		},
		infoData: {
			type: Object,
			default: () => ({
				blocks: null,
				loading: false,
				error: false,
			})
		},
		infoDataTitle: String,
		updatedOn: String,
	},
    data () {
        return {
			doShowDialog: false,
		}
    },
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		computedClass () {
			return [
				this.isMobileView && 'dash-card--mobile',
				this.isTabletView && 'dash-card--tablet',
				this.dark && 'dash-card--dark',
				this.loading && !this.silentLoading && 'dash-card--loading',
				this.noData && 'dash-card--no-data',
				this.hasError && 'dash-card--error',
			]
		},
		headerClass () {
			return this.mainTitle ? 'dash-card__header-main-title' : ''
		},
		infoBlockTitle () {
			return this.infoDataTitle || this.title
		},
		infoBlockContent () {
			const info = this.infoData.blocks?.find(
				(block) => block?.Title.trim?.().toLowerCase() === this.infoBlockTitle.toLowerCase()
			)?.text
			return info ?? 'Description'
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../style/index.scss';

$block: dash-card;
$min-height: 8rem;

.#{$block} {
	--font-title: 1.125rem;
	--font-title-main: 1.5rem;
	--font-info: 0.75rem;
	--padding: 1rem 2rem 2rem;
	--content-opacity: 1;
	--content-min-height: unset;

	&--tablet {
		--font-title: 1rem;
		--font-title-main: 1.25rem;
		--padding: 1rem 1.25rem 1.5rem;
	}

	&--mobile {
		--font-title: 1rem;
		--font-title-main: 1.25rem;
		--padding: 1rem 1rem 1.5rem;
	}

	&--dark {
		background-color: var(--dash-bg);
	}

	&--loading,
	&--no-data {
		--content-opacity: 0.1;
		--content-min-height: 3.5rem;
	}

	&--error {
		--content-opacity: 0.1;
		--content-min-height: 12rem;
	}

	padding: var(--padding);
	display: flex;
	flex-direction: column;
	color: $color-text;
	background-color: var(--card-bg);
	border-radius: $card-border-radius;

	&__header {
		width: 100%;
		min-height: 3rem;
		display: flex;
		align-items: center;
		font-size: var(--font-title);

		&::v-deep .dash-card__title {
			color: $color-water-green;
			font-weight: bold;
		}
	}

	&__title-wrapper {
		display: flex;
		align-items: center;
		flex-grow: 1;
		line-height: 1.2;
	}

	&__header-main-title {
		&::v-deep .dash-card__title-wrapper {
			justify-content: center;
		}

		&::v-deep .dash-card__title {
			color: $color-text;
			font-weight: bold;
			font-size: var(--font-title-main);
		}
	}

	&__title-info {
		display: flex;
		flex-wrap: nowrap;
		align-self: stretch;
		align-items: center;
		margin-left: 1rem;
		column-gap: 0.8rem;
	}

	&__info-updated {
		font-size: var(--font-info);
		line-height: 1;
		display: flex;
		flex-wrap: wrap;
		justify-content: flex-end;
		column-gap: 0.25rem;
		row-gap: 0.25rem;
	}

	&__info-updated-label {
		display: inline-block;
		min-width: max-content;
		font-weight: 500;
	}

	&__info-button {
		// align-self: flex-start;

		&.v-btn--disabled {
			opacity: 0.5;
		}

		.v-icon {
			color: $color-blue;
			font-size: 22px !important;
		}
	}

	&__divider-container {
		height: 4px;
		width: 100%;
		margin-top: 0.25rem;
		flex-shrink: 0;
		display: flex;
		flex-direction: column;
		justify-content: flex-end;
	}

	&__header-divider {
		border-color: $color-text;
		border-top-width: 1px;
	}

	&__content {
		position: relative;
		width: 100%;
		flex-grow: 1;
		min-height: var(--content-min-height);
		display: flex;
		flex-direction: column;
	}

	&__content-data {
		flex-grow: 1;
		width: 100%;
		display: flex;
		flex-direction: column;
		opacity: var(--content-opacity);
		transition: opacity 0.2s;
	}

	&__content-no-data {
		position: absolute;
		inset: 0;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		text-align: center;
		font-size: 1.25rem;
		line-height: 1.5;
		font-weight: 600;
		z-index: 1;
	}

	&__content-error {
		max-width: 30rem;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		color: #ff5252;
		text-align: center;

		.error-btn {
			border-radius: 0.2rem;
			font-weight: 600;

			&:not(.v-btn--text, .v-btn--icon) {
				color: $color-text;
			}
		}
	}
}
</style>
