import { render, staticRenderFns } from "./AcceleratePhase.vue?vue&type=template&id=a81d33ec&scoped=true&"
import script from "./AcceleratePhase.vue?vue&type=script&lang=js&"
export * from "./AcceleratePhase.vue?vue&type=script&lang=js&"
import style0 from "./AcceleratePhase.vue?vue&type=style&index=0&id=a81d33ec&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a81d33ec",
  null
  
)

export default component.exports