const fontFamily = 'Montserrat, georgia, sans-serif'
const textColor = '#FFFFFF'
const chartsPrimaryColor = '#46C3B2'
const chartsSecondaryColor = '#FDFAFA'
const chartsTicksColor = '#073150'

export const getActualVsNeededSubmitChartOptions = (width) => {
	if (width <= 300) {
		return {
			tickSize: 10,
			pivotRadius: 3,
			paneSize: '122.5%',
			paneXCenter: '46.5%',
			paneYCenter: '76%',
			labelFontSize: 15,
			labelDistance: 17,
		}
	} else if (width > 300 && width <= 400) {
		return {
			tickSize: 15,
			pivotRadius: 4,
			paneSize: '128%',
			paneXCenter: '47%',
			paneYCenter: '78%',
			labelFontSize: 16,
			labelDistance: 19,
		}
	} else if (width > 400 && width <= 500) {
		return {
			tickSize: 20,
			pivotRadius: 5,
			paneSize: '135%',
			paneXCenter: '48%',
			paneYCenter: '82%',
			labelFontSize: 18,
			labelDistance: 24,
		}
	} else {
		return {
			tickSize: 25,
			pivotRadius: 6,
			paneSize: '140%',
			paneXCenter: '49%',
			paneYCenter: '85%',
			labelFontSize: 18,
			labelDistance: 26,
		}
	}
}

export const getActualVsNeededSubmitChartConfig = (
	currentData = 0, suggestedData = 0, chartOptions, formatterFn
) => {
	const noData = currentData === 0 && suggestedData === 0
	const isExceeded = currentData >= suggestedData
	let value
	let maxValue
	const ticks = []
	if (noData) {
		value = 0
		maxValue = 1
	} else {
		value = isExceeded ? suggestedData : currentData
		maxValue = suggestedData
		const increment = Math.ceil(maxValue / 6)
		let tick = 0
		while (tick < maxValue) {
			ticks.push(tick)
			tick += increment
		}
		ticks.push(maxValue)	
	}

	const plotBands = noData || isExceeded
		? [{
			from: 0,
			to: maxValue,
			color: noData ? chartsSecondaryColor : chartsPrimaryColor,
			thickness: chartOptions.tickSize,
		}]
		: [{
			from: 0,
			to: value,
			color: chartsPrimaryColor,
			thickness: chartOptions.tickSize,
		}, {
			from: value,
			to: maxValue,
			color: chartsSecondaryColor,
			thickness: chartOptions.tickSize,
		}]

	return {
		chart: {
			type: 'gauge',
			style: {
				fontFamily: fontFamily,
			},
			spacingBottom: 5,
			spacingTop: 10,
			spacingLeft: 0,
			spacingRight: 0,
		},
		title: {
			text: null,
		},
		pane: {
			startAngle: -90,
			endAngle: 90,
			background: null,
			center: [chartOptions.paneXCenter, chartOptions.paneYCenter],
			size: chartOptions.paneSize,
		},
		tooltip: {
			enabled: false,
		},
		yAxis: {
			min: 0,
			max: maxValue,
			tickPosition: 'inside',
			tickColor: chartsTicksColor,
			tickLength: chartOptions.tickSize,
			tickWidth: 2,
			minorTickInterval: null,
			tickPositions: ticks,
			labels: {
				useHTML: true,
				formatter: function () {
					const { value, axis: { tickPositions } } = this
					const index = tickPositions.indexOf(value)
					const style = index < 3
						? index === 0
							? `padding-right:${(3 - index) * 6}px`
							: `padding-right:${(3 - index) * 12}px`
						: index === 6
							? `padding-left:${(index - 3) * 9}px;font-weight:bold`
							: `padding-left:${(index - 3) * 12}px`
					return `
						<span class="with-currency with-currency__chart" style="${style}">
							${formatterFn?.(value, '(0,0)')}
						</span>
					`
				},
				distance: chartOptions.labelDistance,
				y: 0,
				style: {
					fontSize: `${0.8 * chartOptions.labelFontSize}px`,
					color: textColor,
				},
			},
			lineWidth: 0,
			plotBands,
		},
		series: [{
			data: [value],
			dataLabels: {
				useHTML: true,
				formatter: function () {
					const { y } = this
					return `
						<span class="with-currency with-currency__chart">
							${formatterFn?.(isExceeded ? currentData : y, '(0,0.00)')}
						</span>
					`
				},
				borderWidth: 0,
				y: 10,
				style: {
					color: chartsPrimaryColor,
					fontSize: `${chartOptions.labelFontSize}px`,
				},
			},
			dial: {
				radius: '75%',
				backgroundColor: chartsSecondaryColor,
				baseWidth: 2 * chartOptions.pivotRadius,
				baseLength: '0%',
				rearLength: '0%'
			},
			pivot: {
				backgroundColor: chartsSecondaryColor,
				radius: chartOptions.pivotRadius,
			},
		}],
		credits: {
			enabled: false,
		},
	}
}

export const getSubmitProductMixChartOptions = (width, items = 0) => {
	if (width <= 350) {
		const chartHeight = 150 + 50 * items
		return {
			chartHeight,
			legendAlign: 'center',
			legendVerticalAlign: 'bottom',
			legendWidth: '100%',
			legendMargin: 0,
		}
	} else if (width > 350 && width <= 470) {
		if (items <= 3) {
			return {
				chartHeight: 160,
				legendAlign: 'right',
				legendVerticalAlign: 'middle',
				legendWidth: '50%',
				legendMargin: 10,
			}
		} else {
			const chartHeight = 150 + 50 * Math.ceil(0.5 * items)
			return {
				chartHeight,
				legendAlign: 'center',
				legendVerticalAlign: 'bottom',
				legendWidth: '100%',
				legendMargin: 0,
			}
		}
	} else {
		if (items <= 3) {
			return {
				chartHeight: 160,
				legendAlign: 'right',
				legendVerticalAlign: 'middle',
				legendWidth: '50%',
				legendMargin: 10,
			}
		} else {
			const chartHeight = 50 * Math.ceil(0.5 * items)
			const legendWidth = Math.ceil((340 / width) * 100)
			return {
				chartHeight: Math.max(160, chartHeight),
				legendAlign: 'right',
				legendVerticalAlign: 'middle',
				legendWidth: legendWidth + '%',
				legendMargin: 10,
			}
		}
	}
}

export const getSubmitProductMixChartConfig = (data, chartOptions, formatterFn) => ({
	chart: {
		height: chartOptions.chartHeight,
		style: {
			fontFamily: fontFamily,
		},
		spacingBottom: 0,
		spacingTop: 0,
		spacingLeft: 0,
		spacingRight: 0,
	},
	title: {
		text: null,
	},
	plotOptions: {
		pie: {
			cursor: 'pointer',
			innerSize: '70%',
			dataLabels: {
				enabled: false
			},
			showInLegend: true,
		},
		series: {
			borderWidth: 2,
			borderColor: chartsTicksColor,
			type: 'pie',
		}
	},
	tooltip: {
		useHTML: true,
		padding: 0,
		borderRadius: 4,
		formatter: function () {
			const { y, color, key } = this
			return `
				<div class="chart-tooltip-wrapper">
					<p class="chart-tooltip-header" style="color: ${color}">${key}</p>
					<p class="chart-tooltip-point">
						<span class="with-currency">${formatterFn(y)}</span>
					</p>
				</div>
			`
		},
	},
	legend: {
		useHTML: true,
		symbolHeight: 0,
		symbolWidth: 0,
		symbolPadding: 0,
		itemMarginTop: 5,
		itemMarginBottom: 5,
		width: chartOptions.legendWidth,
		align: chartOptions.legendAlign,
		verticalAlign: chartOptions.legendVerticalAlign,
		margin: chartOptions.legendMargin,
		navigation: {
			enabled: false,
		},
		itemHoverStyle: {
			color: '#ffffff99',
		},
		itemHiddenStyle: {
			color: '#ffffff4d',
		},
		itemStyle: {
			color: textColor,
		},
		labelFormatter: function () {
			const { name, y, color } = this
			return `
				<div class="chart-legend-wrapper" style="border-color: ${color}">
					<p class="chart-legend-header">${name}</p>
					<p class="chart-legend-point">
						<span class="with-currency with-currency__chart">${formatterFn(y)}</span>
					</p>
				</div>
			`
		},
	},
	colors: [chartsPrimaryColor, '#8176B8', '#FFB249', '#FFFFFF', '#99CDF7', '#955989'],
	series: [{
		type: 'pie',
		data,
	}],
	credits: {
		enabled: false,
	},
})

export const getSelfLeadershipChartConfig = (data) => ({
	chart: {
		type: 'solidgauge',
		height: '50%',
		style: {
			fontFamily: fontFamily,
		},
		spacingBottom: 5,
		spacingTop: 5,
		spacingLeft: 5,
		spacingRight: 5,
	},
	title: {
		text: null,
	},
	pane: {
		center: ['50%', '100%'],
		size: '200%',
		startAngle: -90,
		endAngle: 90,
		background: {
			shape: 'arc',
			outerRadius: '100%',
			innerRadius: '80%',
			backgroundColor: chartsSecondaryColor,
			borderWidth: 0,
		},
	},
	exporting: {
		enabled: false
	},
	plotOptions: {
		solidgauge: {
			innerRadius: '80%',
			dataLabels: {
				color: textColor,
				y: 10,
				borderWidth: 0,
				useHTML: true,
			},
		},
	},
	tooltip: {
		enabled: false,
	},
	yAxis: {
		min: 0,
		max: 100,
		lineWidth: 0,
		tickWidth: 0,
		minorTickInterval: null,
		tickAmount: 2,
		labels: {
			enabled: false,
		}
	},
	colors: [chartsPrimaryColor],
	series: [{
		data: [data === null ? 0 : data],
		dataLabels: {
			format: `
				<div style="text-align:center">
					<span style="font-size:24px; line-height:1">${data === null ? 'NA' : '{y}'}%</span>
				</div>
			`,
		},
	}],
	credits: {
		enabled: false,
	},
})

export const getProgressTrackerChartConfig = (data, icon) => ({
	chart: {
		type: 'solidgauge',
		height: '50%',
		style: {
			fontFamily: fontFamily,
		},
		spacingBottom: 5,
		spacingTop: 5,
		spacingLeft: 5,
		spacingRight: 5,
	},
	title: {
		text: null,
	},
	pane: {
		center: ['50%', '100%'],
		size: '200%',
		startAngle: -90,
		endAngle: 90,
		background: {
			shape: 'arc',
			outerRadius: '100%',
			innerRadius: '80%',
			backgroundColor: chartsSecondaryColor,
			borderWidth: 0,
		},
	},
	exporting: {
		enabled: false
	},
	plotOptions: {
		solidgauge: {
			innerRadius: '80%',
			dataLabels: {
				color: textColor,
				y: 10,
				borderWidth: 0,
				useHTML: true,
			},
		},
	},
	tooltip: {
		enabled: false,
	},
	yAxis: {
		min: 0,
		max: 100,
		lineWidth: 0,
		tickWidth: 0,
		minorTickInterval: null,
		tickAmount: 2,
		labels: {
			enabled: false,
		}
	},
	colors: [chartsPrimaryColor],
	series: [{
		data: [data === null ? 0 : data],
		dataLabels: {
			format: `
				<div class="data-labels__wrapper">
					${icon}
					<span class="data-labels__text">${data === null ? 'NA' : '{y}'}%</span>
				</div>
			`
		},
		y: 10,
	}],
	credits: {
		enabled: false,
	},
})

export const getPositiveEnergyChartConfig = (data = null) => ({
	chart: {
		type: 'solidgauge',
		height: '100%',
		style: {
			fontFamily: fontFamily,
		},
		spacingBottom: 5,
		spacingTop: 5,
		spacingLeft: 5,
		spacingRight: 5,
	},
	title: {
		text: null,
	},
	subtitle: {
		useHTML: true,
		style: {
			color: textColor,
		},
		text: `
			<div style="text-align:center">
				<span style="font-size:24px; line-height:1">${data === null ? 'NA' : data}%</span>
			</div>
		`,
		floating: true,
		verticalAlign: 'middle',
	},
	pane: {
		center: ['50%', '50%'],
		size: '100%',
		background: {
			shape: 'circle',
			outerRadius: '100%',
			innerRadius: '75%',
			backgroundColor: chartsSecondaryColor,
			borderWidth: 0,
		},
	},
	plotOptions: {
		series: {
			borderWidth: 0,
			borderColor: chartsTicksColor,
			type: 'solidgauge',
			innerRadius: '75%',
			dataLabels: {
				enabled: false,
			},
		},
	},
	tooltip: {
		enabled: false,
	},
	legend: {
		enabled: false,
	},
	yAxis: {
		min: 0,
		max: 100,
		lineWidth: 0,
		tickWidth: 0,
		minorTickInterval: null,
		tickAmount: 0,
		labels: {
			enabled: false,
		},
	},
	colors: [chartsPrimaryColor],
	series: [{
		data: [data === null ? 0 : data],
	}],
	credits: {
		enabled: false,
	},
})

export const getEnergyManagementChartOptions = (width) => {
	if (width <= 300) {
		return {
			chartWidth: 240,
		}
	}
	return {
		chartWidth: 270,
	}
}

export const getEnergyManagementChartConfig = (data, total, subtitle, chartOptions) => ({
	chart: {
		height: 190,
		width: chartOptions.chartWidth,
		style: {
			fontFamily: fontFamily,
		},
		spacing: [0, 0, 0, 0],
	},
	title: {
		text: null,
	},
	subtitle: {
		useHTML: true,
		style: {
			color: textColor,
		},
		text: `
			<div style="max-width:6rem;text-align:center;line-height:1.2; font-weight:bold;">
				<p style="font-size:13px; margin:0; white-space: normal">${subtitle}</p>
				<p style="font-size:20px; margin:0.3rem 0 0">${total}</p>
			</div>
		`,
		floating: true,
		verticalAlign: 'middle',
		x: -44,
		// y: 15,
	},
	plotOptions: {
		pie: {
			cursor: 'pointer',
			dataLabels: {
				enabled: false
			},
			showInLegend: true,
		},
		series: {
			borderWidth: 2,
			borderColor: chartsTicksColor,
			type: 'pie',
			size: chartOptions.pieSize || '100%',
			innerSize: '75%',
		}
	},
	tooltip: {
		useHTML: true,
		padding: 0,
		borderRadius: 4,
		formatter: function () {
			const { y, color, key } = this
			return `
				<div class="chart-tooltip-wrapper">
					<p class="chart-tooltip-header" style="color: ${color}">${key}</p>
					<p class="chart-tooltip-point">
						<span>${y}</span>
					</p>
				</div>
			`
		},
	},
	legend: {
		useHTML: true,
		symbolHeight: 0,
		symbolWidth: 0,
		symbolPadding: 0,
		itemMarginTop: 10,
		width: 80,
		align: 'right',
		verticalAlign: 'middle',
		margin: 0,
		navigation: {
			enabled: false,
		},
		itemHoverStyle: {
			color: '#ffffff99',
		},
		itemHiddenStyle: {
			color: '#ffffff4d',
		},
		itemStyle: {
			color: textColor,
		},
		labelFormatter: function () {
			const { name, y, color } = this
			return `
				<div class="chart-legend-wrapper" style="border-color: ${color}">
					<p class="chart-legend-header">${name}</p>
					<p class="chart-legend-point">
						<span>${y === null ? 'NA' : y}</span>
					</p>
				</div>
			`
		},
	},
	colors: [chartsPrimaryColor, '#FFB249', '#99CDF7', '#8176B8'],
	series: [{
		type: 'pie',
		data,
	}],
	credits: {
		enabled: false,
	},
})

export const getTeamHealthChartConfig = (data = null) => ({
	chart: {
		type: 'solidgauge',
		height: '50%',
		style: {
			fontFamily: fontFamily,
		},
		spacingBottom: 5,
		spacingTop: 5,
		spacingLeft: 5,
		spacingRight: 5,
	},
	title: {
		text: null,
	},
	pane: {
		center: ['50%', '100%'],
		size: '200%',
		startAngle: -90,
		endAngle: 90,
		background: {
			shape: 'arc',
			outerRadius: '100%',
			innerRadius: '80%',
			backgroundColor: chartsSecondaryColor,
			borderWidth: 0,
		},
	},
	exporting: {
		enabled: false
	},
	plotOptions: {
		solidgauge: {
			innerRadius: '80%',
			dataLabels: {
				color: textColor,
				y: 10,
				borderWidth: 0,
				useHTML: true,
			},
		},
	},
	tooltip: {
		enabled: false,
	},
	yAxis: {
		min: 0,
		max: 100,
		lineWidth: 0,
		tickWidth: 0,
		minorTickInterval: null,
		tickAmount: 2,
		labels: {
			enabled: false,
		}
	},
	colors: [chartsPrimaryColor],
	series: [{
		data: [data === null ? 0 : data],
		dataLabels: {
			format: `
				<div style="text-align:center">
					<span style="font-size:22px; line-height:1">${data === null ? 'NA' : '{y}'}%</span>
				</div>
			`,
		},
	}],
	credits: {
		enabled: false,
	},
})

export const getLeadingOthersChartOptions = (width) => {
	if (width <= 300) {
		return {
			align: 'left',
			x: 8,
			y: -6,
			fontSize: 10,
			height: 240,
		}
	} else if (width <= 480) {
		return {
			align: 'left',
			x: 8,
			y: -6,
			fontSize: 12,
			height: 240,
		}
	}
	return {
		align: 'right',
		x: -8,
		y: 4,
		fontSize: 13,
		height: 210,
	}
}
export const getLeadingOthersChartConfig = (data = [], yAxisCategories, chartOptions) => ({
	chart: {
		type: 'bar',
		height: chartOptions.height,
		style: {
			fontFamily: fontFamily,
		},
		spacingBottom: 5,
		spacingTop: 5,
		spacingLeft: 5,
		spacingRight: 5,
	},
	title: {
		text: null,
	},
	plotOptions: {
		bar: {
			borderWidth: 0,
			pointWidth: 5,
			dataLabels: {
				enabled: false,
			},
		},
	},
	tooltip: {
		enabled: false,
	},
	legend: {
		enabled: false,
	},
	xAxis: {
		categories: data.map(item => item[0]),
        title: {
            text: null,
        },
		height: '100%',
        lineWidth: 1,
		gridLineColor: textColor,
		labels: {
			align: chartOptions.align,
			x: chartOptions.x,
			y: chartOptions.y,
			style: {
				color: textColor,
				fontSize: 13,
				fontWeight: 'bold',
				whiteSpace: 'nowrap',
			},
		},
	},
	yAxis: {
		tickPositions: yAxisCategories.map(({ value }) => value),
		gridLineWidth: 0,
		lineWidth: 1,
		gridLineColor: textColor,
		labels: {
			style: {
				color: textColor,
				fontSize: chartOptions.fontSize,
				fontWeight: 'bold',
				whiteSpace: 'nowrap',
			},
			formatter: function () {
				const { value } = this
				const result = yAxisCategories.find((item) => item.value === value).label
				return `<span>${result}</span>`
			},
			align: 'right',
			x: -1,
			y: 16,
			padding: 2,
		},
		title: {
            text: null,
        },
	},
	colors: [chartsPrimaryColor],
	series: [{
		data: data.map(item => ({ y: item[1] })),
	}],
	credits: {
		enabled: false,
	},
})

export const promotionIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="16.547" height="23.79" viewBox="0 0 16.547 23.79">
  <g id="icon_promotion-progress" transform="translate(-1460.374 -578.585)">
    <g id="Group_6234" data-name="Group 6234" transform="translate(1461 579.211)">
      <path id="Path_890" data-name="Path 890" d="M3348.568,1515.825a.624.624,0,0,1,.157.02c2.354.61,4.109,2.4,5.074,5.18a14.327,14.327,0,0,1,.718,3.457,3,3,0,0,1,1.68,1.191,6.126,6.126,0,0,1,.6,4.2,4.928,4.928,0,0,1-3.07,4.255.623.623,0,0,1-.281.019c-.729-.117-1.317-.707-1.747-1.756a4.12,4.12,0,0,1-3.019,1.858h-.081a3.883,3.883,0,0,1-2.971-1.816,5.069,5.069,0,0,1-1.16,1.572.622.622,0,0,1-.467.146,3.6,3.6,0,0,1-2.857-2.229,7.178,7.178,0,0,1-.61-1.88c-.351-2.083-.1-3.657.748-4.681a3.046,3.046,0,0,1,1.545-1,13.843,13.843,0,0,1,1.067-4.2,8.793,8.793,0,0,1,1.883-2.747,6.011,6.011,0,0,1,2.662-1.582A.627.627,0,0,1,3348.568,1515.825Zm4.947,17.049a3.885,3.885,0,0,0,2.045-3.192c0-.014,0-.028.007-.042a5,5,0,0,0-.4-3.255,1.863,1.863,0,0,0-1.34-.774.625.625,0,0,1-.535-.609,12.943,12.943,0,0,0-.686-3.6,6.2,6.2,0,0,0-4.037-4.307,5.225,5.225,0,0,0-1.985,1.278,10.267,10.267,0,0,0-2.558,6.572.611.611,0,0,1-.607.575,1.757,1.757,0,0,0-1.192.66c-.584.724-.744,1.989-.462,3.66,0,0,0,.007,0,.011,0,.026.421,2.592,2.081,3.011a3.624,3.624,0,0,0,.951-2.049.625.625,0,0,1,1.2-.195c.289.662,1.322,2.383,2.6,2.383h.044c1.584-.046,2.676-2.368,2.686-2.391a.625.625,0,0,1,1.186.151A3.561,3.561,0,0,0,3353.515,1532.873Z" transform="translate(-3341.006 -1516.45)" fill="#fff"/>
      <path id="Path_891" data-name="Path 891" d="M3350.713,1530.377a2.895,2.895,0,1,1,2.9-2.895A2.9,2.9,0,0,1,3350.713,1530.377Zm0-4.54a1.645,1.645,0,1,0,1.645,1.645A1.647,1.647,0,0,0,3350.713,1525.837Z" transform="translate(-3343.077 -1518.891)" fill="#fff"/>
    </g>
    <g id="Group_6235" data-name="Group 6235" transform="translate(0.727)">
      <path id="Line_283" data-name="Line 283" d="M0,3.625A.625.625,0,0,1-.625,3V0A.625.625,0,0,1,0-.625.625.625,0,0,1,.625,0V3A.625.625,0,0,1,0,3.625Z" transform="translate(1467.921 598.75)" fill="#fff"/>
      <path id="Line_284" data-name="Line 284" d="M0,2.625A.625.625,0,0,1-.625,2V0A.625.625,0,0,1,0-.625.625.625,0,0,1,.625,0V2A.625.625,0,0,1,0,2.625Z" transform="translate(1470.541 598.625)" fill="#fff"/>
      <path id="Line_285" data-name="Line 285" d="M0,2.625A.625.625,0,0,1-.625,2V0A.625.625,0,0,1,0-.625.625.625,0,0,1,.625,0V2A.625.625,0,0,1,0,2.625Z" transform="translate(1465.301 598.625)" fill="#fff"/>
    </g>
  </g>
</svg>
`

export const progressIcon = `
<svg xmlns="http://www.w3.org/2000/svg" width="23.827" height="15.616" viewBox="0 0 23.827 15.616">
  <g id="icon_summit-progress" transform="translate(-1731.915 -582.421)">
    <path id="Subtraction_2" data-name="Subtraction 2" d="M14.26,14.992H3.985a.625.625,0,0,1-.519-.277L-.52,8.77A.625.625,0,0,1-.53,8.09L4.74-.331a.625.625,0,0,1,1.06,0l8.989,14.364a.626.626,0,0,1-.529.958Zm-9.941-1.25h8.813L5.27,1.179.744,8.41Z" transform="translate(1740.858 583.045)" fill="#fff"/>
    <path id="Path_76259" data-name="Path 76259" d="M5.953.813a.625.625,0,0,1,.516.272l6.412,9.382a.625.625,0,0,1-.516.978H0a.625.625,0,0,1-.548-.324.625.625,0,0,1,.02-.636L5.425,1.1a.625.625,0,0,1,.517-.29Zm5.228,9.382L5.973,2.574,1.137,10.195Z" transform="translate(1732.54 586.592)" fill="#fff"/>
  </g>
</svg>
`
