<template>
	<div class="perform-dash" :class="computedClass" :style="computedStyle">
		<div
			class="perform-dash__bg"
			:class="isMobileView && 'perform-dash__bg_hidden'"
			:style="`background-image: url(${computedHeader})`"
		/>

		<v-container fluid class="perform-dash__container">
			<header class="perform-dash__header">
				<PerformDashTitle
					v-model="activeDashIndex"
					:tabs="computedDashboards"
					:doShowTabs="doShowTabs"
					@click-lock="showDashboardLockedDialog"
					@update:doShowTabs="doShowTabs = $event"
				/>
				<div class="perform-dash__header-filters">
					<div class="perform-dash__period-switcher">
						<v-switch
							v-model="monthlyPeriod"
							:disabled="someDashDataLoading"
							dark
							hide-details
							inset
							:label="monthlyPeriod ? 'Monthly' : 'Weekly'"
						/>
					</div>
                    <QAgentLookup
						v-if="hasRole(['AgencyOwner', 'Staff', 'Exec', 'SuperAdmin'])"
						v-model="agent"
						:filters="{ Status : 'Active,Available,Stalled' }"
						return-object
						:disabled="someDashDataLoading || dashboardsLoading"
						label="Agent"
						placeholder="Start typing to Search for Agent"
						hide-details
						preload-agents
						dark
						class="perform-dash__agent-lookup"
					/>
				</div>
			</header>

			<v-stepper v-model="activeDashIndex" class="px-0">
				<v-stepper-content step="0" class="perform-dash__step">
					<ActivatePhase
						:is-active="activeDashIndex === 0"
						:agent="agentCode"
						:monthlyPeriod="monthlyPeriod"
						:card-mobile-order="isCardMobileOrder"
						:info-data="cmsPage"
						@loading="dashDataLoading.activate = $event"
					/>
				</v-stepper-content>
				<v-stepper-content step="1" class="perform-dash__step">
					<AcceleratePhase
						:is-active="activeDashIndex === 1"
						:agent="agentCode"
						:monthlyPeriod="monthlyPeriod"
						:card-mobile-order="isCardMobileOrder"
						:info-data="cmsPage"
						@loading="dashDataLoading.accelerate = $event"
					/>
				</v-stepper-content>
				<v-stepper-content step="2">
					Advance Dashboard
				</v-stepper-content>
				<v-stepper-content step="3">
					Achieve Dashboard
				</v-stepper-content>
			</v-stepper>
		</v-container>

		<NewDashboardUnlockedDialog
			v-model="doShowNewDashboardUnlockedDialog"
			:content="newDashboardUnlockedDialogContent"
			:loading="cmsPage.loading"
		/>

		<InfoDialog
			v-model="doShowDashboardLockedDialog"
			title="Dashboard Locked"
			:content="dashboardLockedDialogContent"
			:loading="cmsPage.loading"
		/>
	</div>
</template>

<script>
import Page from '@/store/Models/Page'
import QuilityAPI from '@/store/API/QuilityAPI'

import QAgentLookup from '@/components/utils/QAgentLookup.vue'
import InfoDialog from './Components/InfoDialog.vue'
import NewDashboardUnlockedDialog from './Components/NewDashboardUnlockedDialog.vue'
import ActivatePhase from './ActivatePhase.vue'
import AcceleratePhase from './AcceleratePhase.vue'
import PerformDashTitle from './Components/PerformDashTitle.vue'

const dashboardsConfig = [
	{
		phase: 1,
		title: 'Activate',
		disabled: false,
		dashBg: '#000F2C',
		cardBg: '#073150',
		headerBg: require('./style/PerformDash-Activate-Header.svg'),
	},
	{
		phase: 2,
		title: 'Accelerate',
		disabled: true,
		dashBg: '#021A18',
		cardBg: '#0B302D',
		headerBg: require('./style/PerformDash-Accelerate-Header.svg'),
		newDashboardUnlockedBlockTitle: 'Accelerate: Unlocked',
		dashboardLockedBlockTitle: 'Accelerate: Locked',
	},
	{
		phase: 3,
		title: 'Advance',
		disabled: true,
		dashBg: '#0f021a',
		cardBg: '#271143',
		headerBg: null,
		newDashboardUnlockedBlockTitle: 'Advance: Unlocked',
		dashboardLockedBlockTitle: 'Advance: Locked',
	},
	{
		phase: 4,
		title: 'Achieve',
		disabled: true,
		dashBg: '#1a0e02',
		cardBg: '#432111',
		headerBg: null,
		newDashboardUnlockedBlockTitle: 'Achieve: Unlocked',
		dashboardLockedBlockTitle: 'Achieve: Locked',
	},
]

const LOCAL_STORAGE_PHASE_KEY = 'PerformDash-NewPhaseDialog'

export default {
    components: {
		PerformDashTitle,
		QAgentLookup,
		InfoDialog,
		NewDashboardUnlockedDialog,
		ActivatePhase,
		AcceleratePhase,
	},
    data () {
        return {
			agent: undefined,
            monthlyPeriod: true,
            dashDataLoading: {
				activate: false,
				accelerate: false,
				advance: false,
				achieve: false,
			},

			doShowTabs: false,
			activeDashIndex: 0,
            dashboardsLoading: false,

			doShowDashboardLockedDialog: false,
			dashboardLockedDialogContent: '',

			doShowNewDashboardUnlockedDialog: false,
			newDashboardUnlockedDialogContent: '',

			cmsPageSlug: 'perform-dash-info',
			cmsPage: {
				blocks: null,
				loading: false,
				error: false,
			},
        }
    },
	created () {
		this.loadInfoCmsPage()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		isTabletView () {
			return this.$vuetify.breakpoint.smAndDown && !this.isMobileView
		},
		isCardMobileOrder () {
			return this.$vuetify.breakpoint.mdAndDown
		},
		agentCode () {
			return this.agent?.AgentCode ?? this.user.AgentCode
		},
		activeDash () {
			return this.computedDashboards[this.activeDashIndex]
		},
		computedClass () {
			return [
				this.isMobileView && 'perform-dash--mobile',
				this.isTabletView && 'perform-dash--tablet',
				this.isCardMobileOrder && 'perform-dash--mobile-order',
			]
		},
		computedStyle () {
			return {
				'--dash-bg': dashboardsConfig[this.activeDashIndex].dashBg,
				'--card-bg': dashboardsConfig[this.activeDashIndex].cardBg,
			}
		},
		computedHeader () {
			return dashboardsConfig[this.activeDashIndex].headerBg
		},
		computedDashboards () {
			const availableDashboards = this.getDashboards()
			return dashboardsConfig.map(dashboard => {
				const disabled = availableDashboards.find(({ phase }) => phase === dashboard.phase).disabled
				return { ...dashboard, disabled }
			})
		},
		someDashDataLoading () {
			return Object.values(this.dashDataLoading).some(Boolean)
		},
		localStoragePhaseKey () {
			return `${LOCAL_STORAGE_PHASE_KEY}-${this.activeDashIndex + 1}`
		},
    },
	watch: {
		computedDashboards () {
			this.setActiveDash()
		},
		activeDashIndex () {
			this.showNewDashboardUnlockedDialog()
		},
		agent () {
			this.showNewDashboardUnlockedDialog()
		},
	},
    methods: {
		async loadInfoCmsPage () {
			// check store
			let page = Page.query().where('slug', this.cmsPageSlug).first()
			if (page) {
				this.cmsPage.blocks = page.blocks || []
				return Promise.resolve()
			}
			// load from API
			this.cmsPage.loading = true
			await QuilityAPI.getPageWithSlug(this.cmsPageSlug)
			page = Page.query().where('slug', this.cmsPageSlug).first()
			if (page) {
				this.cmsPage.blocks = page.blocks || []
			} else {
				this.cmsPage.error = true
				this.showError('Oops! There was a problem loading the cms page with info descriptions.')
			}
			this.cmsPage.loading = false
		},
		setActiveDash () {
			if (this.activeDash.disabled) {
				this.activeDashIndex = 0
			}
		},
		showNewDashboardUnlockedDialog () {
			if (this.activeDashIndex === 0) { return }
			if (this.agent) { return }
			if (localStorage.getItem(this.localStoragePhaseKey) === 'true') { return }
			this.newDashboardUnlockedDialogContent = this.getDashboardUnlockedContent()
			this.doShowNewDashboardUnlockedDialog = true
			localStorage.setItem(this.localStoragePhaseKey, JSON.stringify(true))
		},
		getDashboardUnlockedContent () {
			const blockTitle = dashboardsConfig[this.activeDashIndex].newDashboardUnlockedBlockTitle
			const info = this.cmsPage.blocks?.find(
				(block) => block?.Title.trim?.().toLowerCase() === blockTitle.toLowerCase()
			)?.text
			return info ?? 'Description'
		},
		showDashboardLockedDialog () {
			this.dashboardLockedDialogContent = this.getDashboardLockedContent()
			this.doShowDashboardLockedDialog = true
		},
		getDashboardLockedContent () {
			const disabledDashboardIndex = this.computedDashboards.findIndex(({ disabled }) => disabled)
			const blockTitle = dashboardsConfig[disabledDashboardIndex].dashboardLockedBlockTitle
			const info = this.cmsPage.blocks?.find(
				(block) => block?.Title.trim?.().toLowerCase() === blockTitle.toLowerCase()
			)?.text
			return info ?? 'Description'
		},
		getDashboards () {
			const agent = this.agent || this.user.Agent
			const isAccelerateAvailable = agent.LeadershipLevel !== 'Sales Representative'
			return [
				{ phase: 1, disabled: false },
				{ phase: 2, disabled: !isAccelerateAvailable },
				{ phase: 3, disabled: true },
				{ phase: 4, disabled: true },
			]
		},
		isActiveDash (item) {
			return item === this.activeDash
		},
    },
}
</script>

<style lang="scss" scoped>
@import './style/index.scss';

$block: perform-dash;

.#{$block} {
	--agent-lookup-width: 21.5rem;
	--header-padding: 0 3rem;
	--header-margin-bottom: 4rem; 
	--header-filters-wrap: nowrap;
	--header-filters-width: unset;

	&--mobile {
		--agent-lookup-width: 100%;
		--header-padding: 0 1rem;
		--header-margin-bottom: 1rem;
		--header-filters-wrap: wrap;
		--header-filters-width: 100%;

		.perform-dash__step {
			padding-left: 0.5rem !important;
			padding-right: 0.5rem !important;
		}
	}

	&--tablet {
		--header-padding: 0 2rem;
		--header-margin-bottom: 1rem;
		--header-filters-width: 100%;
	}

	&--mobile-order {
		.perform-dash__step {
			max-width: 45rem;
			align-self: center;
			padding-left: 1rem !important;
			padding-right: 1rem !important;
		}
	}

	position: relative;
	height: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	color: $color-text;
	background-color: var(--dash-bg);

	&__bg {
		position: absolute;
		top: 0;
		height: 240px;
		width: 100%;
		background-position: center;
		background-size: cover;
		background-repeat: no-repeat;
		opacity: 1;
		transition: opacity 0.2s;
	}

	&__bg_hidden {
		opacity: 0;
	}

	&__container {
		max-width: 2100px;
		padding: 0;
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}

	&__header {
		width: 100%;
		margin-bottom: var(--header-margin-bottom);
		display: flex;
		justify-content: space-between;
		flex-wrap: wrap;
		gap: 2rem;
		z-index: 1;
	}

	&__header-filters {
		width: var(--header-filters-width);
		padding: var(--header-padding);
		display: flex;
		align-items: flex-end;
		justify-content: space-between;
		flex-grow: 1;
		column-gap: 2rem;
		row-gap: 1.25rem;
		flex-wrap: var(--header-filters-wrap);
	}

	&__period-switcher {
		min-width: 9rem;
		margin-bottom: 0.25rem;
		display: flex;
		justify-content: flex-start;

		.v-input--switch {
			margin: 0 0 0 0.25rem;
			padding: 0;
		}

		&::v-deep .v-label {
			font-weight: 600;
			color: $color-text !important;
		}

		&::v-deep .v-input--switch__thumb {
			color: $color-blue !important;
		}

		&::v-deep .v-input--switch__track {
			opacity: 1 !important;
			color: $color-text !important;
		}
	}

	&__agent-lookup {
		width: var(--agent-lookup-width);

		::v-deep .v-input {
			margin-top: 0;
		}
	}
}

.v-stepper {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
	box-shadow: none;
	overflow-y: unset;
	background-color: transparent;

	.v-stepper__content {
		padding-left: 3rem;
		padding-right: 3rem;
	}

	.v-stepper__content,
	&::v-deep .v-stepper__wrapper {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
	}
}
</style>
