<template>
	<DashCard
		title="Total Analog Close Ratio"
		:loading="loading"
		:has-error="hasError"
		:no-data="noLoadedData"
		:info-data="infoData"
		@reload="$emit('reload', 'activate_analog_close_ratio')"
	>
		<template #icon><Icon /></template>

		<div class="card-content" :class="isMobileView && 'card-content--mobile'">
			<div class="content-table__row">
				<div v-for="data in computedData" :key="data[0].key" class="content-table__col">
					<v-data-table
						:headers="headers"
						:items="data"
						item-key="key"
						mobile-breakpoint="0"
						class="card-table"
						dark hide-default-footer hide-default-header disable-sort
					>
						<template #item.metric="{ item }">
							<div><strong>{{ item.metric }}</strong></div>
						</template>
						<template #item.value="{ item }">
							<span class="nowrap" :class="item.comparable && 'font-weight-bold'">
								<span v-if="item.value < 0">-</span>
								<span v-if="item.type === 'currency'" class="with-currency">{{ formatMoney(item.value) }}</span>
								<span v-else-if="item.type === 'percent'" class="nowrap">{{ formatRatio(item.value) }}%</span>
							</span>
						</template>
					</v-data-table>
				</div>
			</div>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import Icon from '../../Icons/TotalAnalogCloseRatioIcon.vue'

const config = [
	{ key: 'MICloseRatio', metric: 'MI Close\u00A0Rate', type: 'percent', position: 1 },
	{ key: 'CICloseRatio', metric: 'CI Close\u00A0Rate', type: 'percent', position: 2 },
]

export default {
    components: {
		DashCard,
		Icon,
	},
	props: {
		data: {
			type: Object,
			default: null,
		},
		infoData: Object,
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		headers () {
			return [{
                text: 'Metric',
                value: 'metric',
            }, {
                text: 'Value',
                value: 'value',
			}]
		},
		loading () {
			if (!this.data) { return false }
			return this.data.loading
		},
		hasError () {
			if (!this.data) { return false }
			return this.data.error
		},
		noLoadedData () {
			if (this.data && this.data.loaded) {
				if (!this.data.data) { return true }
				return Object.values(this.data.data).every(this.checkNullish)
			}
			return false
		},
		computedData () {
			const data = this.data?.data || {}
			const cols = this.isMobileView
				? [1]
				: [...new Set(config.map(item => item.position))].sort((a, b) => a - b)
			return cols.map(pos => config
				.filter(item => this.isMobileView ? true : item.position === pos)
				.map(({ position, ...item }) => ({ ...item, value: data[item.key] }))
			)
		},
    },
	methods: {
		checkNullish (value) {
			return [undefined, null, ''].includes(value)
		},
		formatMoney (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(Math.abs(value), '0,0.00')
		},
		formatRatio (value) {
			if (this.checkNullish(value)) { return 'NA' }
			return this.formatNumber(100 * Math.abs(value), '0[.]0')
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;

.#{$block} {
	color: $color-text;

	--td-padding: 0 0.5rem;

	&--mobile {
		--td-padding: 0 0.75rem;
	}

	.content-table {
		&__row {
			width: 100%;
			display: flex;
			flex-wrap: wrap;
			row-gap: 0;
			column-gap: 0;
		}

		&__col {
			max-width: 100%;
			flex: 1 1 13rem;
		}
	}

	.card-table {
		background-color: transparent;
		color: inherit;
		border-radius: 0;

		::v-deep tbody tr td {
			height: 42px;
			border-bottom: $border-bottom-td;
			padding: var(--td-padding);

			div {
				display: flex;
				align-items: center;
				color: $color-text;
				line-height: 1.2;
			}

			&:first-child {
				background-color: $color-water-green-20;
			}

			&:last-child {
				width: 6.5rem;
			}
		}
	}
}
</style>
