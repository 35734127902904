<template>
	<DashCard
		:title="`${monthlyPeriod ? 'Monthly' : 'Weekly'} Income Goal`"
		info-data-title="Income Goal"
		:info-data="infoData"
		main-title
		dark
	>
		<div class="card-content">
			<div class="card-content__input-wrapper">
				<span ref="observed" class="card-content__observed-input">{{ inputValue }}</span>
				<v-text-field
					:key="inputKey"
					ref="input"
					:value="inputValue"
					:loading="loading"
					:disabled="loading || disable"
					prefix="$"
					dark hide-details
					:style="`--input-width:${inputWidth}`"
					class="card-content__input"
					@input="onInput"
				>
					<template #append><v-icon class="card-content__input-icon">icon-edit-q</v-icon></template>
				</v-text-field>
			</div>

			<div class="card-content__text">
				Enter your desired {{ monthlyPeriod ? 'Monthly' : 'Weekly' }} Income Goal to see the corresponding submit and net placed premium targets, as well as your recommended lead mix
			</div>
		</div>
	</DashCard>
</template>

<script>
import DashCard from '../DashCard.vue'
import EditIcon from '../../Icons/EditIcon.vue'

export default {
    components: {
		DashCard,
		EditIcon,
	},
	props: {
		value: {
			type: Number,
			default: 0,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		loading: {
			type: Boolean,
			default: false,
		},
		disable: {
			type: Boolean,
			default: false,
		},
		infoData: Object,
	},
    data () {
		return {
			inputWidth: '4.5em',
			observer: null,
			inputValue: 0,
			inputKey: 0,
		}
	},
	created () {
		this.inputValue = this.value
	},
	mounted () {
		this.initContainerObserver()
	},
	beforeDestroy () {
		this.destroyContainerObserver()
	},
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
    },
	watch: {
		value () {
			this.inputValue = this.value
		},
	},
    methods: {
		initContainerObserver () {
			if (this.$refs.observed) {
				this.observer = new ResizeObserver(this.resizeInput)
				this.observer.observe(this.$refs.observed)
			}
		},
		destroyContainerObserver () {
			if (this.observer) {
				this.observer.disconnect?.()
				this.observer = null
			}
		},
		resizeInput (entries) {
			const parent = this.$refs.input.$el.querySelector('.v-text-field__slot')
			const width = parent
				? Math.floor(getComputedStyle(parent).width?.slice?.(0,-2)) || null
				: null
			const inputContentWidth = Math.ceil(entries[0].contentRect.width) + 5
			if (!width) {
				this.inputWidth = `${inputContentWidth}px`
			} else {
				const maxWidth = width - 20
				this.inputWidth = `${Math.min(inputContentWidth, maxWidth)}px`
			}
		},
		onInput (value) {
			const caretPosition = this.getCaretPosition()
			const processed = this.processNumber(value)
			if (processed !== this.value) {
				this.$emit('input', processed)
			} else {
				this.inputValue = processed
				this.inputKey += 1
				this.$nextTick(() => {
					this.$refs.input.focus()
					this.setCaretPosition(caretPosition)
				})
			}
		},
		processNumber (value) {
			const processed = [...value]
				.filter(char => char !== ' ' && !Number.isNaN(Number(char)))
				.join('')
			const number = Number(processed)
			if (number < 0) { return 0 }
			if (number > 10000000) { return 10000000 }
			return number
		},
		getCaretPosition () {
			const input = this.$refs.input.$el.querySelector('input')
			return input.selectionStart
		},
		setCaretPosition (position) {
			const input = this.$refs.input.$el.querySelector('input')
			return input.setSelectionRange(position - 1, position - 1)
		},
	},
}
</script>

<style lang="scss" scoped>
@import '../../style/index.scss';

$block: card-content;
$q_blue_1: #0083eb;

.#{$block} {
	--font-size: 1rem;
	--font-size-btn: 1.375rem;
	--font-size-input: 1.875rem;

	&--mobile {
		--font-size: 1rem;
		--font-size-btn: 1.375rem;
		--font-size-input: 1.875rem;
	}

	color: $color-text;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	margin-top: 0.5rem;

	&__input-wrapper {
		position: relative;
		width: 100%;
	}

	&__observed-input {
		position: absolute;
		left: 0;
		top: 0;
		visibility: hidden;
		font-size: var(--font-size-input);
		line-height: var(--font-size-input);
		max-height: var(--font-size-input);
		user-select: none;
		pointer-events: none;
		z-index: -1;
	}

	&__input {
		width: 100%;
		margin-top: 0;
		padding-top: 0;
		font-size: var(--font-size-input);

		::v-deep .v-input__slot {
			padding-bottom: 4px;

			&::before {
				border: 1px solid $color-blue !important;
			}

			.v-text-field__slot {
				justify-content: center;

				.v-text-field__prefix {
					display: inline-flex;
					align-items: flex-start;
					align-self: stretch;
					font-size: 0.6em;
					line-height: 1;
					padding-right: 2px;
					opacity: 0.75;
				}

				input {
					flex-grow: 0;
					width: var(--input-width);
					max-width: calc(100% - 10px);
					padding: 0;
					font-size: var(--font-size-input);
					line-height: var(--font-size-input);
					max-height: var(--font-size-input);
				}
			}

			.v-input__append-inner {
				margin: 0 0 0 auto;
				align-self: center;
			}
		}
	}

	&__input-icon {
		color: #ffffff54;
		font-size: 20px !important;
	}

	&__text {
		margin-top: 1.5rem;
		font-size: var(--font-size);
		text-align: center;
	}

	&__btn {
		height: 3.375rem !important;
		padding: 0 2.5rem !important;
		margin-top: 1.5rem;
		color: $color-text !important;
		background-color: $q_blue_1 !important;
		border-radius: 5px;
		font-size: var(--font-size-btn);
		font-weight: 600;

		&.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
			color: $color-text !important;
			background-color: $q_blue_1 !important;
			opacity: 0.4;
		}
	}
}
</style>
