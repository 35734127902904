<template>
	<div :key="agentCode" class="accelerate-phase" :class="computedClass">
		<div class="accelerate-phase__board-row">
			<div class="accelerate-phase__board-col col-production">
				<ProductionCard
					:data="cardsData.production"
					:monthly-period="monthlyPeriod"
					:info-data="infoData"
					@reload="reloadData"
				/>
			</div>
			<div class="accelerate-phase__board-col col-recruiting">
				<RecruitingAndOnboardingCard
					:data="cardsData.recruitingAndOnboarding"
					:monthly-period="monthlyPeriod"
					:info-data="infoData"
					@reload="reloadData"
				/>
			</div>
			<div class="accelerate-phase__board-col col-activation">
				<ActivationCard
					:data="cardsData.activation"
					:monthly-period="monthlyPeriod"
					:info-data="infoData"
					@reload="reloadData"
				/>
			</div>
		</div>
		<div>
			<AOPromotionTrackerCard
				:data="cardsData.AOPromotionTracker"
				:info-data="infoData"
				:card-mobile-order="cardMobileOrder"
				@reload="reloadData"
			/>
		</div>
		<div class="accelerate-phase__board-row">
			<div class="accelerate-phase__board-col col-positive-energy">
				<PositiveEnergyCard
					:data="cardsData.positiveEnergy"
					:info-data="infoData"
					:card-mobile-order="cardMobileOrder"
					@reload="reloadData"
				/>
			</div>
			<div class="accelerate-phase__board-col col-energy-management">
				<EnergyManagementCard
					:data="cardsData.energyManagement"
					:monthly-period="monthlyPeriod"
					:info-data="infoData"
					:card-mobile-order="cardMobileOrder"
					@reload="reloadData"
				/>
			</div>
			<div class="accelerate-phase__board-col col-team-health">
				<TeamHealthCard
					:data="cardsData.teamHealth"
					:info-data="infoData"
					:card-mobile-order="cardMobileOrder"
					@reload="reloadData"
				/>
			</div>
			<div class="accelerate-phase__board-col col-leading-others">
				<LeadingOthersCard
					:data="cardsData.leadingOthers"
					:info-data="infoData"
					:card-mobile-order="cardMobileOrder"
					@reload="reloadData"
				/>
			</div>
		</div>
	</div>
</template>

<script>
import QuilityAPI from '@/store/API/QuilityAPI'

import ProductionCard from './DashCards/AcceleratePhase/ProductionCard.vue'
import RecruitingAndOnboardingCard from './DashCards/AcceleratePhase/RecruitingAndOnboardingCard.vue'
import ActivationCard from './DashCards/AcceleratePhase/ActivationCard.vue'
import AOPromotionTrackerCard from './DashCards/AcceleratePhase/AOPromotionTrackerCard.vue'
import PositiveEnergyCard from './DashCards/AcceleratePhase/PositiveEnergyCard.vue'
import TeamHealthCard from './DashCards/AcceleratePhase/TeamHealthCard.vue'
import EnergyManagementCard from './DashCards/AcceleratePhase/EnergyManagementCard.vue'
import LeadingOthersCard from './DashCards/AcceleratePhase/LeadingOthersCard.vue'

const modules = [
	'accelerate_production',
	'accelerate_recruiting_onboarding',
	'accelerate_activation',
	'accelerate_ao_tracker',
	'accelerate_positive_energy',
	'accelerate_energy_management',
	'accelerate_team_health',
	'accelerate_leading_others',
]
const getEmptyModulesData = () => modules.reduce((acc, item) => {
	acc[item] = undefined
	return acc
}, {})

export default {
    components: {
		ProductionCard,
		RecruitingAndOnboardingCard,
		ActivationCard,
		AOPromotionTrackerCard,
		PositiveEnergyCard,
		EnergyManagementCard,
		TeamHealthCard,
		LeadingOthersCard,
	},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
		agent: {
			type: String,
		},
		monthlyPeriod: {
			type: Boolean,
			default: true,
		},
		cardMobileOrder: {
			type: Boolean,
			default: false,
		},
		infoData: Object,
	},
    data () {
        return {
			dataCache: {},
			dataLoading: false,
			dataLoadingError: false,

			modules,
			modulesData: getEmptyModulesData(),
		}
    },
    computed: {
		isMobileView () {
			return this.$vuetify.breakpoint.xs
		},
		agentCode () {
			return this.agent ?? this.user.AgentCode
		},
		cardsData () {
			return {
				production: this.modulesData.accelerate_production,
				recruitingAndOnboarding: this.modulesData.accelerate_recruiting_onboarding,
				activation: this.modulesData.accelerate_activation,
				AOPromotionTracker: this.modulesData.accelerate_ao_tracker,
				positiveEnergy: this.modulesData.accelerate_positive_energy,
				energyManagement: this.modulesData.accelerate_energy_management,
				teamHealth: this.modulesData.accelerate_team_health,
				leadingOthers: this.modulesData.accelerate_leading_others,
			}
		},
		computedClass () {
			return [
				this.isMobileView && 'accelerate-phase--mobile',
				this.cardMobileOrder && 'accelerate-phase--mobile-order',
			]
		},
		mode () {
			return { isActive: this.isActive, agentCode: this.agentCode }
		},
    },
	watch: {
		mode: {
			immediate: true,
			handler () {
				if (!this.mode.isActive) { return }
				this.updateData()
			},
		},
		dataLoading () {
			this.$emit('loading', this.dataLoading)
		},
	},
    methods: {
		async updateData () {
			if (!this.dataCache[this.agentCode]) {
				this.dataCache[this.agentCode] = {}
			}
			await this.loadData(this.modules)
		},
		async loadData (modules) {
			this.resetModulesState(modules)
			// check cache
			const uncachedModules = []
			modules.forEach(module => {
				if (this.dataCache[this.agentCode][module] && !this.dataCache[this.agentCode][module].error) {
					this.modulesData[module] = { ...this.dataCache[this.agentCode][module] }
				} else {
					uncachedModules.push(module)
				}
			})
			if (!uncachedModules.length) {
				return Promise.resolve()
			}
			// call API
			this.setModulesLoading(uncachedModules)
			this.dataLoading = true
			this.dataLoadingError = false
			const promises = uncachedModules.map(module => QuilityAPI.loadPerformDashModule(module, this.agentCode))
			const responses = await Promise.all(promises)
			responses.forEach((res, i) => {
				const state = { loaded: true }
				if (res.error) {
					state.data = null
					state.loading = false
					state.error = true
				} else {
					state.data = this.processData(res)
					state.loading = false
					state.error = false
					this.dataCache[this.agentCode][uncachedModules[i]] = state
				}
				this.modulesData[uncachedModules[i]] = { ...state }
			})
			this.dataLoadingError = Object.values(this.modulesData).every(({ error }) => error)
			this.dataLoading = false
		},
		processData (res) {
			if (this.checkEmptyData(res.data)) { return null }
			return res.data
		},
		checkEmptyData (data) {
			if (!data) { return true }
			if (Array.isArray(data) && !data.length) { return true }
			if (typeof data === 'object' && !Object.keys(data).length) { return true }
			return false
		},
		resetModulesState (modules) {
			modules.forEach(module => {
				if (!this.modulesData[module]) {
					this.modulesData[module] = {}
				}
				this.modulesData[module].data = null
				this.modulesData[module].loading = false
				this.modulesData[module].error = false
				this.modulesData[module].loaded = false
			})
		},
		setModulesLoading (modules) {
			modules.forEach(module => {
				this.modulesData[module].loading = true
			})
		},
		reloadData (module) {
			this.loadData([module])
		},
	},
}
</script>

<style lang="scss" scoped>
@import './style/index.scss';

$block: accelerate-phase;

.#{$block} {
	--row-gap: 2.5rem;
	--col-gap: 2rem;

	width: 100%;
	color: $color-text;
	display: flex;
	flex-direction: column;
	row-gap: var(--row-gap);
	column-gap: var(--col-gap);

	&__board-row {
		width: 100%;
		display: flex;
		flex-wrap: wrap;
		row-gap: var(--row-gap);
		column-gap: var(--col-gap);
	}

	&__board-col {
		max-width: 100%;
	}

	.col-production {
		flex: 1 1 320px;
	}

	.col-recruiting {
		flex: 1 1 320px;
	}

	.col-activation {
		flex: 1 1 320px;
	}

	.col-positive-energy {
		flex: 1 0 230px;
	}

	.col-energy-management {
		flex: 1 0 280px;
	}

	.col-team-health {
		flex: 10 1 320px;
	}

	.col-leading-others {
		flex: 10 1 380px;
	}
}
</style>
